import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

@Injectable({providedIn: 'root'})
export class CovenantUploaderService {
  constructor(private readonly http: HttpClient) {}

  importSellingsReportFile(
    systemName = 'bank',
    contractId: number,
    realtyTypes: number[],
    frequencyType: string,
    file: FormData
  ) {
    return this.http.post<{data: []; status: number}>(
      `${environment.api}/${systemName}/backoffice/v1/covenant/importSellingsReportFile`,
      file,
      {
        params: {
          contractId: contractId.toString(),
          realtyTypes: realtyTypes.map(x => x.toString()),
          frequencyByYear: frequencyType == "byYear"
        },
      },
    );
  }

  importSpendingsReportFile(systemName = 'bank', file: FormData) {
    return this.http.post<{data: []; status: number}>(
      `${environment.api}/${systemName}/backoffice/v1/covenant/importSpendingsReportFile`,
      file,
    );
  }
}
