<ng-container *ngIf="columnConfig.type != 'fake'">
  <div
    *ngIf="
      columnConfig.sidePanel &&
      !value &&
      (!columnConfig.sidePanel.filter ||
        columnConfig.prop != columnConfig.sidePanel.filter[0][0])
    "
    class="cell-value"
  >
    <span class="cell-value-btn" (click)="openSidePanel()">
      Добавить
      <mat-icon>add</mat-icon>
    </span>
  </div>
  <ng-container *ngIf="columnConfig.sidePanel && value && !disableDeeper">
    <span class="cell-value-prefix" (click)="openSidePanel(value)">
      <mat-icon>vertical_split</mat-icon>
    </span>
  </ng-container>
</ng-container>

<ng-container [ngSwitch]="columnConfig.type">
  <ng-container *ngSwitchCase="'fake'">
    <div class="cell-value cell-value--input" (click)="openSidePanel()">
      {{ value ? columnConfig.label : 'Открыть' }}{{ value ? ' (' + value.length
      + ')' : '' }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'string'">
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--input"
      [class.mark]="wasEdited"
      [matTooltip]="columnConfig.tooltip && value.length > 25 ? value : null"
      [ngClass]="{'cell-value--editable': editable}"
      (click)="valueClick()"
    >
      {{ value ? value : '' }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'text'">
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--input cell-value--multiline"
      [class.mark]="wasEdited"
      [matTooltip]="columnConfig.tooltip && value.length > 25 ? value : null"
      [ngClass]="{'cell-value--editable': editable}"
      (click)="valueClick()"
    >
      {{ value }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'json'">
    <div class="cell-value cell-value--input cell-value--multiline">
      {{ jsonValue }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'int'">
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--input"
      [class.mark]="wasEdited"
      [ngClass]="{'cell-value--editable': editable}"
      (click)="valueClick()"
    >
      {{ value }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'float'">
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--input"
      [ngClass]="{'cell-value--editable': editable}"
      (click)="valueClick()"
    >
      {{ value }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'color'">
    <div
      class="cell-prefix cell-prefix--color"
      [style.background]="_value"
      [cpPresetColors]="['#FF0000', '#00B345', '#00B0F0']"
      [(colorPicker)]="_value"
      (cpToggleChange)="$event || onBlur()"
    ></div>
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--input"
      [ngClass]="{'cell-value--editable': editable}"
      (click)="valueClick()"
    >
      {{ value }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'boolean'">
    <div
      *ngIf="!editable; else editField"
      class="cell-checkbox cell-checkbox--disabled"
    >
      <mat-icon>checked</mat-icon>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'select'">
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--select"
      [ngClass]="{
        'cell-value--editable': editable,
        'cell-value--select-multiple': columnConfig.typeProps?.multiple
      }"
    >
      <ng-container *ngIf="columnConfig.typeProps?.multiple">
        <span
          *ngFor="let item of _value.slice(0, 3); let index = index"
          class="cell-value--multiple-item cell-value--deletable"
          (click)="removeItem(index)"
        >
          {{ item[columnConfig.typeProps.key] }}
        </span>
        <span *ngIf="_value.length > 3" class="cell-value--multiple-counter">
          И ещё {{ _value.length - 3 }}...
        </span>
        <span
          *ngIf="editable"
          class="cell-value--add-toggle"
          (click)="valueClick()"
        >
          Добавить
          <mat-icon>arrow_drop_down</mat-icon>
        </span>
      </ng-container>
      <ng-container *ngIf="!columnConfig.typeProps?.multiple">
        <div
          class="cell-value--single-item cell-value--input"
          (click)="valueClick()"
        >
          {{ row[columnConfig.typeProps.linkedKey || columnConfig.prop] }}
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'date'">
    <div
      *ngIf="!editing; else editField"
      class="cell-value cell-value--input"
      [ngClass]="{'cell-value--editable': editable}"
      (click)="valueClick()"
    >
      {{ formattedValue }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'action'">
    <div
      class="btn btn--green"
      [ngClass]="{'btn--disabed': isLoading}"
      (click)="action()"
    >
      {{ isLoading ? 'Обновляется...' : columnConfig.label }}
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'coordinates'">
    <div
      class="btn btn--green"
      [ngClass]="{'btn--disabed': isLoading}"
      (click)="openCoordinates()"
    >
      {{ columnConfig.label }} ({{ row[columnConfig.prop].length }})
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'covenantUpload'">
    <div
      class="btn btn--green"
      [ngClass]="{'btn--disabed': isLoading}"
      (click)="openCovenantUploader()"
    >
      {{ columnConfig.label }}
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="cell-value">{{ value }}</div>
  </ng-container>
</ng-container>

<ng-template #editField>
  <app-table-field-edit
    [value]="_value"
    [columnConfig]="columnConfig"
    [selectFilterParams]="selectFilterParams"
    [row]="row"
    (onSelected)="updateSelect($event)"
    (onDateSelected)="onDateBlur($event)"
    (onTextSelected)="onTextBlur($event)"
    (onCheckSelected)="valueClick($event)"
  ></app-table-field-edit>
</ng-template>
