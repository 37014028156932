import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NotificationsService} from '@core/services/notifications.service';
import {FormControl, Validators} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  Optional,
} from '@angular/core';
import {CovenantUploaderService} from './covenant-uploader.service';
import {ContractCashflowArticleService} from './contract-cashflow-article.service';
import {catchError, finalize, takeUntil} from 'rxjs/operators';
import {EMPTY, Subject} from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'covenant-uploader',
  templateUrl: './covenant-uploader.template.html',
  styleUrls: ['./covenant-uploader.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CovenantUploaderComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @HostBinding('class._loading') loading = false;

  readonly covenantTypes = [
    {value: 'sellings', label: 'Ковенанта по продажам'},
    {value: 'spendings', label: 'Ковенанта по расходам'},
  ];

  readonly frequencyTypes = [
    {value: 'byQuarter', label: 'По кварталам'},
    {value: 'byYear', label: 'По годам'},
  ];

  readonly realtyTypes$ = this.$contractCashflowArticle.getContractCashflowArticles();

  readonly covenantType = new FormControl('sellings', Validators.required);
  readonly realtyType = new FormControl([2], Validators.required);
  readonly file = new FormControl(null, Validators.required);
  readonly frequencyType = new FormControl('byQuarter', Validators.required);

  constructor(
    private readonly $uploader: CovenantUploaderService,
    private readonly $contractCashflowArticle: ContractCashflowArticleService,
    private readonly dialogRef: MatDialogRef<CovenantUploaderComponent>,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private readonly data: {row: any; systemName: string},
    @Optional() private readonly $notifications?: NotificationsService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.upload();
  }

  onFileAdd(input: HTMLInputElement) {
    this.file.setValue(input.files && input.files.length > 0 ? input.files[0] : null);
  }

  private upload() {
    const fileFormData = new FormData();
    fileFormData.append('file', this.file.value);

    switch (this.covenantType.value) {
      case 'sellings':
        this.loading = true;
        this.$uploader
          .importSellingsReportFile(
            this.data.systemName,
            this.data.row.id,
            this.realtyType.value,
            this.frequencyType.value,
            fileFormData,
          )
          .pipe(
            finalize(() => {
              this.loading = false;
              this.cdr.markForCheck();
            }),
            catchError(error => {
              if (!!this.$notifications) {
                this.$notifications.showError(
                  error.error.Status,
                  error.error.Error,
                  10000,
                );
              }

              return EMPTY;
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(res => {
            if (res && res.status === 200 && !!this.$notifications) {
              this.$notifications.showMessage(
                'Ковенанты по продажамм загружены',
                'done',
                2500,
              );
            }

            this.close();
          });
        break;

      case 'spendings':
        this.loading = true;
        this.$uploader
          .importSpendingsReportFile(this.data.systemName, fileFormData)
          .pipe(
            finalize(() => {
              this.loading = false;
              this.cdr.markForCheck();
            }),
            catchError(error => {
              if (!!this.$notifications) {
                this.$notifications.showError(
                  error.error.Status,
                  error.error.Error,
                  10000,
                );
              }

              return EMPTY;
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(res => {
            if (res && res.status === 200 && !!this.$notifications) {
              this.$notifications.showMessage(
                'Ковенанты по расходам загружены',
                'done',
                2500,
              );
            }

            this.close();
          });
        break;

      default:
        break;
    }
  }
}
