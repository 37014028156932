<fieldset
  class="loader-content"
  [class.loader-content--loading]="loading"
  [disabled]="loading"
>
  <div class="header">
    <div class="header__title">Загрузка ковенант</div>
  </div>
  <div class="content">
    <div class="form">
      <ng-select
        placeholder="Ковенанта"
        bindValue="value"
        class="app-custom-select app-custom-select--required"
        [formControl]="covenantType"
        [searchable]="false"
        [items]="covenantTypes"
        [clearable]="false"
        [required]="true"
      ></ng-select>

      <ng-select
        *ngIf="covenantType.value === 'sellings'"
        placeholder="Периодичность"
        bindValue="value"
        class="app-custom-select app-custom-select--required"
        [formControl]="frequencyType"
        [searchable]="false"
        [items]="frequencyTypes"
        [clearable]="false"
        [required]="true"
      ></ng-select>

      <ng-select
        *ngIf="covenantType.value === 'sellings'"
        placeholder="Тип недвижимости"
        bindValue="id"
        bindLabel="name"
        class="app-custom-select app-custom-select--default-multiple app-custom-select--required"
        [formControl]="realtyType"
        [searchable]="false"
        [items]="realtyTypes$ | async"
        [multiple]="true"
        [required]="true"
        [closeOnSelect]="false"
      ></ng-select>
    </div>

    <p>
      Для загрузки ковенант по укажите файл с данными. Допустимы только xls
      файлы определенного формата.
    </p>
    <ul>
      <li>
        <a
          *ngIf="covenantType.value === 'sellings'"
          href="https://docs.google.com/spreadsheets/d/16XjKxeetR8IUUqQ20C9dUpmhEbR-hf6H/edit?usp=sharing&ouid=118383152623003089807&rtpof=true&sd=true"
          target="_blank"
          title="Ссылка на шаблон по продажам"
        >
          Скачать шаблон файла для загрузки
        </a>
      </li>
      <li>
        <a
          *ngIf="covenantType.value === 'spendings'"
          href="https://docs.google.com/spreadsheets/d/1EPLWWN2siTSwloXMQ0iOzx7rCwDnYgqn/edit?usp=sharing&ouid=118383152623003089807&rtpof=true&sd=true"
          target="_blank"
          title="Ссылка на шаблон по затратам"
        >
          Скачать шаблон файла для загрузки
        </a>
      </li>
      <li>
        <a
          href="https://info.pik.ru/spaces/common/pages/13710"
          target="_blank"
          title="Инструкция по загрузке ковенант"
        >
          Инструкция по загрузке ковенант
        </a>
      </li>
    </ul>

    <button
      mat-raised-button
      color="primary"
      class="file-input-button"
      (click)="fileInput.click()"
    >
      <mat-icon>attachment</mat-icon>
      &nbsp;{{ fileInput.files && fileInput.files.length > 0 ?
      fileInput.files[0].name : 'Выбрать файл' }}
    </button>
    <input
      #fileInput
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      class="hidden-file-input"
      (change)="onFileAdd(fileInput)"
    />
  </div>

  <div class="footer">
    <button mat-raised-button (click)="close()">Отменить</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="file.invalid"
      (click)="save()"
    >
      Сохранить
    </button>
  </div>
</fieldset>

<div *ngIf="loading" class="loader">
  <svg
    focusable="false"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    class="loader-icon"
  >
    <circle
      cx="50"
      cy="50"
      r="50"
      stroke-dasharray="314"
      class="loader-circle"
    ></circle>
  </svg>
</div>
